@use '@angular/material' as mat;
.work-application-container {
    padding: 0 100px;

    .header {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;

        .header-item {
            width: 360px;
            display: inline-flex;
            align-items: baseline;
        }
    }

    .c-user-application {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
        grid-gap: 20px;
        margin-top: 15px;
        width: 100%;

        a {
            text-decoration: none;
            text-transform: uppercase;
        }

        .user-application {
            display: flex;
            flex-direction: column;
            @include mat.elevation(8, #000000, 0.07);

            .application-title, h2 {
                font-size: 18px;
            }

            &.new {
                @include mat.elevation(8, #000000, 0.07);
                justify-content: center;
                border: 2px dashed;
                opacity: 0.25;
                transition: all 0.5s ease-out;
                height: 100%;
                min-height: 157px;

                .mat-mdc-card-title {
                    display: none;
                }

                .mat-mdc-card-content {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .mat-mdc-icon {
                    height: 48px;
                    width: 48px;
                    font-size: 48px;
                }

                .mat-mdc-card-actions {
                    justify-content: center;
                }
            }

            &.new:hover {
                cursor: pointer;
                opacity: 1;

                .mat-mdc-button.mat-primary {
                    color: var(--primary-color);
                }
            }

            &.favorite {
                border-color: var(--orange-color);
            }

            .mat-mdc-card-title.application-header {
                display: flex;
                justify-content: space-between;
                align-items: baseline;

                h3 {
                    color: var(--primary-color);
                }
            }

            .mat-mdc-card-actions {
                display: flex;
                justify-content: flex-end;
                flex-grow: 0;
                padding-right: 0;
                padding-bottom: 0;
                padding-left: 0;

                .mdc-icon-button {
                    span {
                        display: flex;
                    }
                }

                .material-symbols-outlined {
                    color: var(--gray-color);

                    &.favorite {
                        color: var(--orange-color);
                    }
                }
            }

            .mat-mdc-chip-action-label {
                display: flex;
                align-items: center;
                gap: 5px;
                line-height: 1.1;

                .material-symbols-outlined {
                    font-size: 20px;
                }
            }

            .mat-mdc-chip-set .mdc-evolution-chip {
                padding-top: 2px;
                padding-bottom: 2px;
            }
        }
    }
}

.application-requirement-list {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: max-content;
    background-color: white;
    width: 330px;
    .mdc-list {
        padding: 0;
    }
}

.application-requirement-item {
    display: grid;
    grid-template-columns: 1fr max-content;
    column-gap: 10px;
    padding: 8px 16px;
    align-items: center;
    cursor: pointer;

    .icon-list {
        display: grid;
        grid-template-columns: max-content max-content;
        align-items: center;
        gap: 8px;
    }

    .fab-text {
        color: white;
        font-size: 14px;
    }

    &:hover {
        background-color: rgba(141, 141, 141, 0.1);
        border-radius: 4px;
    }

    mat-chip {
        cursor: pointer;
        zoom: 80%;
    }
}

.application-requirement-detail {
    background-color: var(--text-primary-color);
    border-radius: 4px;
    @include mat.elevation(8, #000000, 0.07);
    margin-bottom: 15px;

    .application-header {
        display: grid;
        grid-template-columns: auto max-content;
        column-gap: 10px;
        color: var(--application-header-color);
        background-color: var(--application-header-background-color);
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        padding: 26px 16px;

        .title-card {
            font-size: 32px;
            font-weight: 500;
            align-self: center;
            margin: 0;
        }

        .actions-card {
            display: grid;
            grid-auto-flow: column;
        }

        .mat-primary {
            color: white;
        }

        .mat-icon.mat-warn {
            color: white;
        }
    }

    .application-details-content {
        padding: 15px;
        display: grid;
        grid-auto-rows: min-content;
        gap: 30px;
    }

    .application-details-header {
        display: grid;
        grid-auto-rows: auto;
        row-gap: 30px;

        .form-field-inactive {
            margin: 0;
        }

        .form-inactive-label {
            font-size: 18px;
            font-weight: 500;
        }
    }

    .application-sub-title {
        display: flex;
        place-items: center;
        gap: 5px;
        font-size: 18px;
        font-weight: 500;
        color: #9d9fa1;
        margin-bottom: 15px;

        .icon-title {
            display: flex;
        }
    }

    .app-component-container {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 0.5fr));
        grid-gap: 20px;

        a {
            text-decoration: none;
            text-transform: uppercase;
        }

        .app-container-item {
            display: grid;
            grid-template-columns: auto max-content;
            column-gap: 10px;
            align-items: center;
            @include mat.elevation(8, #000000, 0.07);
            background-color: var(--theme-accent-color);
            color: var(--text-primary-color);
            border-radius: 6px;
            padding: 10px;
            cursor: pointer;
            transition: ease all 0.4s;

            &:hover {
                background-color: var(--success-color-dark);
            }
        }

        information-system-add-application-card, application-add-app-component-card {
            .app-container-item {
                background-color: var(--mdc-outlined-card-container-color);
                border-radius: var(--mdc-outlined-card-container-shape);
                color: var(--theme-accent-color);
                border: 2px dashed;
                opacity: 0.4;
                &:hover {
                    opacity: 1;
                    background-color: #ffffff;
                }
            }
        }

        .app-container-title {
            display: flex;
            align-items: center;
            gap: 5px;
            .title-item-badge {
                display: flex;
                gap: 0;
                flex-direction: column;
                align-items: flex-start;
            }

            .icon {
                height: 24px;
                width: 24px;

                .mat-icon {
                    height: 24px;
                    width: 24px;
                    font-size: 24px;
                }
            }
        }
    }
}

.tag-container {
    margin-top: 5px;
    .tag-solo {
        font-weight: 500;
        background-color: var(--primary-lighter-color);
        color: #2c2c2c;
        padding: 2px 10px 3px;
        border-left: 3px solid var(--primary-color);
        border-radius: 4px;
    }

    .tag {
        font-weight: 500;
        background-color: var(--primary-lighter-color);
        color: #2c2c2c;
        padding: 5px 5px 3px 10px;
        border-left: 3px solid var(--primary-color);
        border-radius: 6px 0 0 6px;
        box-shadow: rgba(0, 0, 0, 0.05) 0 6px 24px 0,
            rgba(0, 0, 0, 0.08) 0 0 0 1px;
        &.value {
            background-color: #efefef;
            border-left: none;
            color: var(--primary-color);
            border-radius: 0 6px 6px 0;
            padding: 5px 10px 3px;
        }
    }
}

.tooltip {
    &-red {
        background: var(--warn-color);
    }
}

.mdc-tooltip__surface {
    font-size: 13px !important;
    line-height: 1.2 !important;
}

.warning-message {
    font-size: 16px;
    font-weight: 520;
}

.logo-organization {
    max-width: 20vw;
    max-height: 40vh;
    border-radius: 6px;
}

.file-input {
    display: none;
}

.margin-logo {
    margin-right: 15px;
}

.file-preview {
    margin-left: 15px;
    height: 50px;
}

.file-upload {
    display: flex;
    align-items: center;
}

.referent-title {
    font-size: 15px;
}

.organization-detail {
    display: flex;
    justify-content: space-between;
}

.mat-mdc-button {
    text-decoration: none;

    .no-min-width & {
        min-width: 0;
    }
}

branch-merge-element h1 {
    margin-top: 40px;
}

.branch-merge-change-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.branch-merge-change-wrapper .change p {
    margin: 0;
    + p {
        margin-top: 4px;
    }
}

.branch-merge-change-wrapper .mat-expansion-panel-body {
    padding: 0 16px 4px !important;
    .mat-expansion-panel-body {
        padding: 0 0 4px 16px !important;
    }
}

loader-skeleton-loader {
    .skeleton-placeholder {
        display: flex;
        height: 100%;
        width: 100%;
        align-items: center;
        justify-content: center;
    }
    .placeholder-p {
        display: inline-block;
        padding: 12px 24px;
        border-radius: 8px;
        border: solid 1px var(--gray-chat);
    }
}

.changes-navigation {
    height: calc(100vh - 260px);
    overflow: auto;

    mat-list-item {
        flex-shrink: 0 !important;
        padding: 0 15px 15px 15px !important;
    }

    mat-list-item,
    .mdc-list-item.mdc-list-item--with-three-lines {
        height: auto !important;
    }

    .mdc-list-item--with-two-lines .mdc-list-item__primary-text, .mdc-list-item--with-three-lines .mdc-list-item__primary-text {
        margin-bottom: -10px;
    }

    .date {
        margin-top: 10px;
    }

    .mat-mdc-list-item.mdc-list-item--with-three-lines .mat-mdc-list-item-line.mdc-list-item__secondary-text {
        white-space: normal !important;
    }
}

.form-login {
    .mat-mdc-form-field-error-wrapper mat-error {
        white-space: normal;
        margin-bottom: 30px;
        margin-top: -16px;
    }

    .mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
        position: relative;
    }
}
