@use '@angular/material' as mat;

/* * {
    scroll-margin-bottom: 80px; // commenter car problème avec ckeditor et autoscroll => quick fix
}*/

html,
body {
    height: 100%;
}

body {
    margin: 0;

    &.drawer-opened {
        overflow: hidden;
    }

    &:has(.work-application-container) .super-sidebar {
        width: 0;
        padding: 0 0;
        overflow: hidden;
    }
}

.mat-mdc-text-field-wrapper {
    .mat-mdc-form-field-flex {
        align-items: center !important;

        .mat-mdc-form-field-text-prefix {
            margin-right: 10px;
        }
    }
}

h3 {
    font-weight: 500;
}

.layout-main-content {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: var(--toolbar-height) 1fr;
    background-color: $light-background; //TEMP
    height: 100vh;
}

.main-layout-container {
    display: flex;
    height: 100%;
    overflow: hidden;
}

branch-merge loader-skeleton-loader {
    display: flex;
    flex-direction: column;
    gap: 15px;
    height: 100%;

    .mdc-card:first-child {
        flex-grow: 1;
    }
}

.super-sidebar {
    display: flex;
    flex-direction: column;
    background-color: var(--theme-background-color);
    color: var(--theme-text-color);

    &--dark {
        --theme-text-color: var(--dark-theme-text-color);
        --theme-background-color: var(--dark-theme-background-color);
    }

    &--light {
        --theme-text-color: var(--light-theme-text-color);
        --theme-background-color: var(--light-theme-background-color);
    }

    &--alt {
        --theme-text-color: var(--alt-theme-text-color);
        --theme-background-color: var(--alt-theme-background-color);
    }
}

.main-container {
    position: relative;
    padding: 20px 20px 0 20px !important;
    height: calc(100% - 20px);
    overflow: auto;
    flex-grow: 1;

    &--dark {
        --theme-accent-color: var(--dark-theme-accent-color);
        --theme-text-color: var(--dark-theme-text-color);
        --theme-background-color: var(--dark-theme-background-color);
        --theme-active-text-color: var(--dark-theme-active-text-color);
        --theme-active-nobg-text-color: var(--dark-theme-active-nobg-text-color);
        --theme-active-background-color: var(--dark-theme-active-background-color);
        --theme-border-color: var(--dark-theme-border-color);
        --theme-active-item-background-color: var(--dark-theme-active-item-background-color);
    }
    &--light {
        --theme-accent-color: var(--light-theme-accent-color);
        --theme-text-color: var(--light-theme-text-color);
        --theme-background-color: var(--light-theme-background-color);
        --theme-active-text-color: var(--light-theme-active-text-color);
        --theme-active-nobg-text-color: var(--light-theme-active-nobg-text-color);
        --theme-active-background-color: var(--light-theme-active-background-color);
        --theme-border-color: var(--light-theme-border-color);
        --theme-active-item-background-color: var(--light-theme-active-item-background-color);
    }
    &--alt {
        --theme-accent-color: var(--alt-theme-accent-color);
        --theme-text-color: var(--alt-theme-text-color);
        --theme-background-color: var(--alt-theme-background-color);
        --theme-active-text-color: var(--alt-theme-active-text-color);
        --theme-active-nobg-text-color: var(--alt-theme-active-nobg-text-color);
        --theme-active-background-color: var(--alt-theme-active-background-color);
        --theme-border-color: var(--alt-theme-border-color);
        --theme-active-item-background-color: var(--alt-theme-active-item-background-color);
    }



    .breadcrumb {
        display: none;
    }
}

.toolbar-application {
    z-index: 1000;
    position: sticky;
    top: 0;
    font-weight: 400;

    &.toolbar-application {
        &--lean {
            .switcher-select.ng-star-inserted * {
                border: none;
            }

            .mdc-text-field--outlined .mat-mdc-form-field-infix,
            .mdc-text-field--no-label .mat-mdc-form-field-infix {
                padding-top: 4px;
                padding-bottom: 4px;
            }

            .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
                &[ng-reflect-floating="false"] {
                    top: 15px;
                }

                &[ng-reflect-floating="true"] {
                    padding-left: 3px;
                }
            }
        }

        &--dark {
            --theme-text-color: var(--dark-theme-text-color);
            --theme-background-color: var(--dark-theme-background-color);
        }

        &--light {
            --theme-text-color: var(--light-theme-text-color);
            --theme-background-color: var(--light-theme-background-color);
        }

        &--alt {
            --theme-text-color: var(--alt-theme-text-color);
            --theme-background-color: var(--alt-theme-background-color);
        }

        .mat-toolbar {
            background-color: var(--theme-background-color);
        }

        .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
            background-color: var(--theme-background-color);
        }

        .mat-mdc-text-field-wrapper:hover {
            --mdc-outlined-text-field-hover-outline-color: var(--theme-text-color);
            --mdc-outlined-text-field-label-text-color: var(--theme-text-color);
            --mdc-outlined-text-field-hover-label-text-color: var(--theme-text-color);
        }

        --mat-select-enabled-trigger-text-color: var(--theme-text-color);
        --mdc-outlined-text-field-label-text-color: var(--theme-text-color);
        --mat-toolbar-container-text-color: var(--theme-text-color);
        --mdc-outlined-text-field-outline-color: var(--theme-text-color);
        --mat-select-enabled-arrow-color: var(--theme-text-color);
        --mdc-icon-button-icon-color: var(--theme-text-color);
        --mdc-outlined-text-field-input-text-color: var(--theme-text-color);
        --mat-icon-button-state-layer-color: var(--theme-text-color);
        --mdc-outlined-text-field-focus-label-text-color: var(--theme-text-color);
        // --mdc-outlined-text-field-focus-outline-color: var(--theme-text-color):
        // --mat-select-focused-arrow-color: var(--theme-text-color);
    }

    .mat-toolbar {
        background-color: #ffffff;
        @include mat.elevation(8, #000000, 0.07);
    }

    .mat-toolbar-row {
        display: grid;
        grid-template-columns: auto max-content;
        column-gap: 15px;
        height: var(--toolbar-height);
    }

    .application-switcher {
        // display: grid;
        display: flex;
        // grid-template-columns: max-content 400px;
        align-items: center;
        column-gap: 20px;
    }

    .application-actions {
        display: flex;
        align-items: center;

        .mat-mdc-form-field-subscript-wrapper {
            display: none;
        }
    }

    .mat-mdc-form-field-infix {
        min-height: auto;
    }
}

.switcher-select {
    .mat-mdc-form-field-focus-overlay {
        background-color: transparent;
    }

    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
        background-color: transparent;
    }

    .mat-mdc-form-field-subscript-wrapper {
        display: none;
    }
}

.mat-menu-item {
    &.logout {
        color: var(--warn-color);
    }
}

.search-space {
    position: relative;
    width: 200px;
    font-size: 16px;

    .searchbar {
        display: flex;
        align-items: center;

        .filters-field {
            margin-left: 20px;
            flex: 1;
        }

        .mat-mdc-form-field-prefix,
        .mat-mdc-form-field-suffix {
            padding: 10px;
        }

        .mdc-text-field--filled:not(.mdc-text-field--disabled) {
            background-color: transparent;
        }

        .mat-mdc-form-field-focus-overlay {
            background-color: transparent;
        }
    }

    .search-container {
        position: absolute;
        right: 0;
        top: calc(var(--toolbar-height) - 14px);
        max-height: calc(100vh - var(--toolbar-height) * 2);
        width: 400px;
        color: #000000;
        background-color: white;
        border-radius: 4px;
        box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
        white-space: normal;
        overflow-y: auto;

        .overview-item {
            border: 4px;
            padding: 5px 20px;
        }

        .overview-item.selected {
            border-left: 4px solid var(--primary-color);
            padding-left: 16px;
            background-color: rgba(131, 131, 133, 0.1);
        }

        .overview-item:hover {
            background-color: rgba(131, 131, 133, 0.1);
        }

        .overview-title {
            .search-name {
                cursor: pointer;
                line-height: 1.2;
                font-weight: 500;
                font-size: 15px;

                h3 {
                    display: inline;
                    word-break: break-all;
                }
            }
        }

        .type {
            font-size: 12.5px;
            opacity: 80%;
            font-style: italic;
        }

        .description {
            font-size: 13.5px;
            cursor: pointer;
        }
    }

    .highlight {
        color: var(--primary-color);
    }

    @media screen and (max-width: 1200px) {
        width: 100%;
        font-size: 14px;

        .search-container {
            min-width: 500px;
            right: 0;
            left: auto;

            .overview-title {
                .search-name {
                    h3 {
                        font-size: 14px;
                        white-space: break-spaces;
                    }

                    .mat-chip {
                        font-size: 11px;
                    }
                }

            }

            .type {
                font-size: 11px;
            }

            .description {
                font-size: 12px;
            }
        }
    }
}

.mat-mdc-menu-panel {
    .mdc-list-group__subheader {
        margin: 10px 20px;
    }
}

.mat-drawer-side.mat-drawer-end {
    z-index: 1 !important;
    background-color: transparent;
    border: none;
}

.breadcrumb {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    border-radius: 4px;

    &:hover,
    &.active {
        background-color: var(--primary-color);
    }

    .back-button {
        height: 24px;
    }

    .type {
        height: 20px;
        font-size: 11px;
        opacity: 0.5;
    }

    .list-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin: 0;
        padding: 0;

        .list-item {
            display: flex;
            place-items: center;
            font-family: Roboto;

            &:first-child:before {
                content: '';
                margin: 0;
            }

            .item {
                display: flex;
                flex-direction: column;
            }

            a {
                text-decoration: none;
                color: inherit;
            }

            .dropdown-arrow {
                vertical-align: middle;
                cursor: pointer;
            }
        }
    }
}

.contextual-menu {

    a .mat-mdc-menu-item-text {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .search {
        padding: 0 20px;

        .mat-icon {
            vertical-align: middle;
            margin-right: 5px;
        }
    }

    .mat-mdc-menu-item.menu-section {
        border-top: 1px solid black;
        text-transform: uppercase;
    }

    .mat-mdc-form-field-appearance-outline .mat-form-field-wrapper {
        padding-bottom: 0;
    }
}

.drawer-close {
    width: 100%;

    .mat-button {
        padding: 0;
        min-width: 36px;
    }

    &.right {
        text-align: right;
    }

    &.left {
        text-align: left;
    }
}

.mat-accordion {
    .mat-expansion-panel {
        .mat-expansion-panel-header:not(.expansion-table-header) {
            //font-size: 24px;
            font-weight: 400;
            padding: 6px 20px;

            .mat-expansion-panel-header-title {
                padding: 10px 0;
            }
        }

        .mat-expansion-panel-content {
            //font-size: 18px;
        }

        .mat-card {
            padding: 0;
            @include mat.elevation(8, #000000, 0.07);
        }
    }
}

.mat-cards-group {
    display: flex;
    flex-direction: column;
    gap: 15px;
    flex-grow: 1;
    padding-bottom: 20px;

    .mat-mdc-card {
        margin: 0;
    }
}

.mat-mdc-card {
    &.table-card {
        margin: 20px 0;
    }

    &.first-card {
        margin-top: 0;
    }

    &.element-card {
        margin: 30px 0;
    }

    &.sticky-card {
        position: sticky;
        top: 0;
        z-index: 100;
    }

    &.sticky-card--bottom {
        position: sticky;
        bottom: 0;
        margin-left: -20px;
        width: calc(100% + 40px);
        z-index: 10;
        border-radius: 0;
        background-color: #f4f4f4;
        padding: 5px 20px;
        box-shadow:
            0px -5px 5px -3px rgba(0, 0, 0, 0.07),
            0px -8px 10px 1px rgba(0, 0, 0, 0.07),
            0px -3px 14px 2px rgba(0, 0, 0, 0.07);

        &.hidden {
            display: none;
        }

        .label-card {
            font-size: 16px;
            font-weight: 500;
        }

        .add-scenario {
            font-size: small;
        }

        .scroll-button {
            text-transform: initial;

            mat-icon {
                margin-top: -5px;
            }
        }

        .title-button+.title-button {
            margin-left: 16px;
        }

        .action-button+.action-button {
            margin-left: 16px;
        }
    }

    &.transparent-card {
        box-shadow: none !important;
        background-color: transparent !important;
    }
}

.first-card {
    h1 {
        font-size: 30px;
    }
}

.mat-form-field .mat-error {
    font-size: 10px;
    font-weight: 400;
    line-height: 1;
    font-family: 'Noto Sans', serif;
    letter-spacing: 1.5px;
}

.save-button {
    .mat-button-wrapper {
        display: inline-flex;
        align-items: center;

        .progress-spinner {
            margin-right: 5px;
        }

        span {
            align-self: baseline;
        }
    }
}

.save-status {
    display: inline-flex;
    align-items: center;

    .progress-spinner {
        margin-right: 5px;
    }

    span {
        align-self: baseline;
    }
}

.loading-progress-bar {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 18px;

    .mat-mdc-progress-bar {
        width: 50vw;
        margin-bottom: 10px;
        height: 10px;
    }

    .mdc-linear-progress__bar-inner {
        border-top-width: var(--mdc-linear-progress-track-height, 10px);
    }

    @media (max-width: 767px) {
        .mat-mdc-progress-bar {
            width: 80vw;
        }
    }
}

.mat-expansion-panel-header-title {
    font-size: 23px;
    font-weight: 400;
}

.user-menu {
    .dropdown {
        padding: 3px 16px !important;

        .mat-mdc-form-field-focus-overlay {
            background-color: transparent;
        }

        .mdc-text-field--filled:not(.mdc-text-field--disabled) {
            background-color: transparent;
        }

        .mat-mdc-form-field-subscript-wrapper {
            display: none;
        }
    }
}

.mat-expansion-panel {
    .mat-button-toggle-group {
        overflow: auto;
        max-width: 250px;
        max-height: 100%;

        .mat-pseudo-checkbox {
            display: none;
        }
    }

    .mat-button-toggle {
        white-space: break-spaces;
    }
}
