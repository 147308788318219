button {
    font-family: 'Noto Sans', 'Helvetica Neue', sans-serif;
}

.mdc-button__label {
    display: flex;
    align-items: center;
    gap: 3px;
    text-align: left;
}

.mdc-button {
    height: auto;
    padding: 6px 10px;

    &--raised {
        padding: 0 12px !important;
    }
}

.button-link-icon {
    background-color: transparent;
    border: none;
    padding: 0;
    font-size: unset;

    &:hover {
        cursor: pointer;
    }
}

.mat-expansion-panel-header.mat-expanded {
    height: auto;
}

.mat-mdc-menu-trigger {
    min-width: unset !important;

    .mat-icon {
        margin-right: 0 !important;
    }
}

.mat-mdc-button {
    margin-left: -8px;
    & + .mat-mdc-button {
        margin-left: initial;
    }
}

.mat-mdc-menu-panel {
    .mat-mdc-button {
        margin-left: initial;
    }
}
.mat-mdc-menu-panel {
    .mat-mdc-button {
        margin-left: initial;
    }
}

td {
    .mat-mdc-button.mat-mdc-button-base, .mat-mdc-raised-button.mat-mdc-button-base, .mat-mdc-unelevated-button.mat-mdc-button-base, .mat-mdc-outlined-button.mat-mdc-button-base {
        height: auto;
    }
    button.mat-mdc-icon-button {
        &.mat-mdc-button-base {
            zoom: 0.9;
        }
    }
}

.mat-mdc-icon-button {
    display: inline-flex!important;
}

.badge {
    &.badge-pill {
        background-color: #888888;
        padding: 1px 6px;
        border-radius: 15px;
        font-size: 70%;
    }
}
